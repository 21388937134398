import { useState } from "react";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import Control from "../../../../../common/Controls/Control";
import dataService from "../../../../../../helpers/dataService";
import { toast } from "react-toastify";
import SaveBtn from "../../../../../common/SaveBtn";

const ChooseInvoiceAddress = ({
  billingAddresses,
  defaultAddress,
  invoice,
  onAddressSelect,
}) => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [selectedAddress, setSelectedAddress] = useState(
    defaultAddress?.id || null
  );
  const [isGenerating, setIsGenerating] = useState(false);

  const onOpenInvoice = () => {
    //redirect to  /invoice/${el.id}/download in a new window

    if (!selectedAddress) {
      alert("Veuillez d'abord sélectionner une adresse");
      return;
    }
    const selectedAddressData = billingAddresses.find(
      (b) => b.id == selectedAddress
    );
    //TODO appel api pour lier l'adresse à la facture
    setIsGenerating(true);
    dataService.post(
      `invoices/${invoice.id}/generate`,
      {
        address1: selectedAddressData.address1,
        address2: selectedAddressData.address2,
        postalCode: selectedAddressData.postalCode,
        city: selectedAddressData.city,
      },
      (data) => {
        onAddressSelect();
      },
      (err) => {
        toast.error("Erreur interne : " + err.other);
      },
      () => setIsGenerating(false)
    );
  };

  return (
    <div className="p-4 d-center flex-column">
      <div className=" py-2">
        <h2>Choix de l'adresse de facturation</h2>
        <Control
          label="À quelle adresse souhaitez-vous rattacher cette facture ?"
          labelClassname="border-0 px-0"
          type="btnList"
          name="selectedAddress"
          value={selectedAddress}
          datas={
            billingAddresses?.map((b) => ({
              ...b,
              addressFull: `${b.address1} ${b.address2} ${b.postalCode} ${b.city}`,
            })) || []
          }
          change={(e) => setSelectedAddress(e.target.value)}
          dataIndex="id"
          dataLabel="name"
          dataLabel2="addressFull"
        />
        <p className="">
          <i className="fa fa-warning mr-1" /> Une fois la facture téléchargée,
          elle ne pourra plus être rattachée à une autre adresse.
        </p>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-default"
            onClick={() => {
              modal_actions.updateModal({ isOpen: false, content: null });
            }}
          >
            fermer
          </button>
          <SaveBtn
            isSaving={isGenerating}
            save={() => onOpenInvoice()}
            type="primary"
            text="Télécharger la facture"
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseInvoiceAddress;
