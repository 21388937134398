import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";
import Table from "../../../../../common/Table/Table";
import ChooseInvoiceAddress from "./ChooseInvoiceAddress";
import useInvoices from "./useInvoices";

const Invoices = () => {
  const [state, dispatch] = useStore();
  const { subState, getInvoices, isLoading } = useInvoices(true);
  const modal_actions = modalActions(state, dispatch);

  const { INVOICE_STATUS } = state.constants.items;

  useEffect(() => {
    getInvoices();
  }, [subState.id]);

  const onOpenInvoice = (invoice) => {
    if (invoice.generatedAt) {
      window.open(`/invoice/${invoice.id}/download`, "_blank");
      return;
    }
    if (subState.billingAddresses.length === 0) {
      modal_actions.updateModal({
        isOpen: true,
        content: (
          <div className="p-2 d-center flex-column">
            <div className="d-center flex-column py-2">
              <p>
                Pour télécharger une facture, le coffre doit posséder au moins
                une adresse de facturation.
              </p>
              <p>
                Fermez cette fenêtre puis cliquez sur{" "}
                <strong>Ajouter une nouvelle adresse</strong>.
              </p>
              <button
                className="btn btn-default"
                onClick={() => {
                  modal_actions.updateModal({ isOpen: false, content: null });
                }}
              >
                fermer
              </button>
            </div>
          </div>
        ),
      });
      return;
    }

    modal_actions.updateModal({
      isOpen: true,
      content: (
        <ChooseInvoiceAddress
          billingAddresses={subState.billingAddresses}
          defaultAddress={subState.billingAddress}
          invoice={invoice}
          onAddressSelect={() => {
            modal_actions.updateModal({ isOpen: false, content: null });
            window.open(`/invoice/${invoice.id}/download`, "_blank");
            getInvoices();
          }}
        />
      ),
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <strong style={{ fontSize: 22 }}>Factures</strong>
      <Table
        fields={[
          {
            name: "",
            path: "*",
            render: (el) => {
              return (
                <div>
                  {el.createdAt && (
                    <strong className="d-block">
                      {DateTime.fromISO(el.createdAt).toFormat("dd/MM/yyyy")}
                    </strong>
                  )}
                  {/* <small>{el.reference}</small> */}
                </div>
              );
            },
          },
          {
            name: "",
            path: "amount",
            render: (el) => {
              return `${tools.truenumberWithSpaces(el / 100)}€ TTC`;
            },
          },
          {
            name: "",
            path: "*",
            render: (el) => {
              if (!el.id) return null;
              var ref = tools.findIn(INVOICE_STATUS, el.status, "id");
              return (
                <div
                  className={`p-2 mr-1 d-flex align-items-center text-${ref.color}`}
                  style={{ fontSize: 14 }}
                >
                  <i className={`fa fa-${ref.icon} mr-1`} />
                  {ref.name}
                </div>
              );
            },
          },
          {
            name: "",
            path: "*",
            render: (el) => {
              if (!el.id) return null;
              var ref = tools.findIn(INVOICE_STATUS, el.status, "id");
              return (
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => onOpenInvoice(el)}
                >
                  Télécharger
                </button>
              );
            },
          },
        ]}
        datas={
          subState.invoices?.length > 0
            ? subState.invoices
            : [
                {
                  reference: "Aucune facture",
                },
              ]
        }
      />
    </>
  );
};

export default Invoices;
