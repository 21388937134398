import React from "react";
import Loader from "../../../../../common/Loader";
import useBillingAddresses from "./useBillingAddresses";

const DeleteBillingAddress = ({ onFinish }) => {
  const {
    isLoadingAddresses,
    deleteBillingAddress,
    getBillingAddresses,
    isSaving,
    subState,
    isDeleting,
  } = useBillingAddresses();

  return (
    <>
      {isLoadingAddresses ? (
        <Loader />
      ) : !subState.billingAddresses ? null : (
        <div>
          <div className="d-flex flex-column">
            {subState.billingAddresses
              .sort((a, b) => (a.id == subState.billingAddress.id ? -1 : 1))
              .map((address) => {
                let inAddress = `${address.address1} ${address.address2} ${address.postalCode} ${address.city}`;
                return (
                  <div
                    className="d-flex flex-row align-items-center justify-content-between mb-1 p-2 rounded bg-default"
                    key={`addre${address.id}`}
                  >
                    <div>
                      <div>
                        <strong>{address.name}</strong>
                      </div>
                      <div>{inAddress}</div>
                    </div>
                    {isDeleting == address.id ? (
                      <Loader />
                    ) : address.id == subState.billingAddress.id ? (
                      <span>par défaut</span>
                    ) : (
                      <button
                        className="btn btn-danger d-inline-flex btn-sm"
                        onClick={() => deleteBillingAddress(address.id)}
                      >
                        Supprimer
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="d-flex justify-content-between">
            <button className="btn btn-link" onClick={onFinish}>
              Retour
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteBillingAddress;
