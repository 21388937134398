import React, { useEffect, useState } from "react";
import CreateBillingAddress from "./CreateBillingAddress";
import ModifyBillingAddress from "./ModifyBillingAddress";
import SelectBillingAddress from "./SelectBillingAddress";
import DeleteBillingAddress from "./DeleteBillingAddress";
import ActionButton from "./ActionButton";
import useBillingAddresses from "./useBillingAddresses";

const BillingAddress = ({ disabled }) => {
  const { subState, getBillingAddresses } = useBillingAddresses();
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    getBillingAddresses();
  }, []);

  const [mode, setMode] = useState(false);

  const onFinishSelect = () => {
    setMode(false);
    setSelectedAddress(null);
  };
  const onFinishModify = () => {
    setMode(false);
    setSelectedAddress(null);
  };
  const onFinishCreate = () => {
    setMode(false);
    setSelectedAddress(null);
  };
  const onFinishDelete = () => {
    setMode(false);
    setSelectedAddress(null);
  };

  return (
    <>
      <strong
        className="d-flex justify-content-between align-items-center mb-2"
        style={{ fontSize: 22 }}
      >
        Adresses de facturation{" "}
        {subState.billingAddresses && (
          <small className="bg-default rounded px-2 text-secondary d-inline-center">
            {subState.billingAddresses.length}
          </small>
        )}
      </strong>

      {!mode && (
        <div>
          {subState.billingAddresses?.length > 0 ? (
            <ul style={{ fontSize: 16 }}>
              {subState.billingAddresses
                .sort((a, b) => (a.id == subState.billingAddress.id ? -1 : 1))
                .map((address) => {
                  const isDefault = address.id == subState.billingAddress.id;
                  return (
                    <li className="mb-2">
                      <div
                        className="text-secondary d-flex align-items-center flex-wrap"
                        style={{ gap: 8 }}
                      >
                        <span className="text-uppercase font-weight-bold">
                          {address.name}
                        </span>{" "}
                        {isDefault && (
                          <span className="badge badge-secondary">
                            par defaut
                          </span>
                        )}
                      </div>
                      <div>
                        {address.address1} {address.address2}{" "}
                        {address.postalCode} {address.city}
                      </div>
                      <button
                        className="btn text-primary px-0 btn-sm"
                        onClick={() => {
                          setSelectedAddress(address);
                          setMode("modify");
                        }}
                      >
                        <i className="fa fa-edit mr-1" />
                        Modifier
                      </button>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <div>
              <small className="text-muted">
                Aucune adresse de facturation n'a encore été ajoutée.
              </small>
            </div>
          )}
          {!disabled && (
            <div className=" d-flex flex-column">
              <ActionButton onClick={() => setMode("create")}>
                <i className="fa fa-plus mr-2" />
                Ajouter une adresse
              </ActionButton>
              {subState.billingAddresses &&
                subState.billingAddresses.length > 1 && (
                  <>
                    <ActionButton onClick={() => setMode("delete")}>
                      <i className="fa fa-times mr-2" />
                      Supprimer une adresse
                    </ActionButton>
                    <ActionButton onClick={() => setMode("change")}>
                      <i className="fa fa-list mr-2" />
                      Changer l'adresse par défaut
                    </ActionButton>
                  </>
                )}
            </div>
          )}
        </div>
      )}

      {mode == "change" ? (
        <SelectBillingAddress onFinish={onFinishSelect} />
      ) : mode == "modify" ? (
        <ModifyBillingAddress
          onFinish={onFinishModify}
          selectedAddress={selectedAddress}
        />
      ) : mode == "create" ? (
        <CreateBillingAddress onFinish={onFinishCreate} />
      ) : mode == "delete" ? (
        <DeleteBillingAddress onFinish={onFinishDelete} />
      ) : null}
    </>
  );
};

export default BillingAddress;
