import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import { useSubscriptionStore } from "../../subscriptionContext/SubscriptionStore";
import BillingAddress from "./BillingAddress/BillingAddress";
import ChestList from "./ChestList";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Invoices from "./Invoices/Invoices";
import useStore from "../../../../../context/useStore";
import { initSubscription } from "../../subscriptionContext/subscriptionReducer";
import SubscriptionDropDown from "./SubscriptionDropdown";
import { AnimatePresence, motion } from "framer-motion";
import UncancelSubscriptionButton from "./UncancelSubscriptionButton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscriptionItem = ({ gState, isSubOpen = false, k }) => {
  const [state] = useStore();
  const [subState, dispatch] = useSubscriptionStore();
  const { SUBSCRIPTION_STATUS } = state.constants.items;
  const [isOpen, setIsOpen] = useState(isSubOpen);

  useEffect(() => {
    dispatch(initSubscription(gState));
  }, [gState]);

  const totalAmount = subState.chests.reduce(
    (a, b) => {
      return {
        shopSubscription: {
          amount: a.shopSubscription.amount + b.shopSubscription.amount,
        },
      };
    },
    { shopSubscription: { amount: 0 } }
  );

  const toggleOpen = () => {
    setIsOpen((o) => !o);
  };

  var subStatus = tools.findIn(SUBSCRIPTION_STATUS, subState.status, "id");

  var isCanceled = subState.status == "CANCELED";
  const cancelDate = subState.canceledAt || DateTime.local().toISO();

  var isDisabled = ["CANCELED", "UNPAID"].includes(subState.status);

  const toggleIcon = isOpen ? "eye-slash" : "eye";
  const toggleText = isOpen ? "MASQUER LES DETAILS" : "AFFICHER LES DETAILS";

  return (
    <div className="mb-3 px-2 ">
      <div className="row mx-0">
        <div className="col-12 bg-verylight border p-3 rounded mb-2 shadow-sm">
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <h3
              className={`mb-0 ${
                isDisabled ? "text-black-50" : "text-secondary"
              }`}
              style={{ fontSize: 22 }}
            >
              <div className={` text-${subStatus.color}`}>
                <i className={`fa fa-${subStatus.icon} mr-2`} />
                {tools.findIn(SUBSCRIPTION_STATUS, subState.status, "id").name}
              </div>
            </h3>
            {!isCanceled && (
              <>
                <div
                  className="text-white text-primary d-flex flex-column align-items-end font-weight-bold"
                  style={{ fontSize: 22 }}
                >
                  {tools.truenumberWithSpaces(
                    tools.numberWithTrailingZero(
                      totalAmount.shopSubscription.amount
                    ) / 100
                  )}{" "}
                  €/mois
                </div>
              </>
            )}
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-between align-items-end">
            <div
              className="text-black-50 d-flex flex-column"
              style={{ fontSize: 20 }}
            >
              <small>
                Souscrit le{" "}
                {DateTime.fromISO(subState.createdAt).toFormat("dd/MM/yyyy")}
              </small>
              <small>
                Echéance le{" "}
                {DateTime.fromISO(subState.echeance).toFormat("dd/MM/yyyy")}
              </small>
              {isCanceled && (
                <small>
                  Résilié le{" "}
                  {DateTime.fromISO(cancelDate).toFormat("dd/MM/yyyy")}
                </small>
              )}
              {!isDisabled && (
                <div className="mt-2">
                  <SubscriptionDropDown
                    totalAmount={totalAmount.shopSubscription.amount}
                  />
                </div>
              )}
              <UncancelSubscriptionButton cancelDate={cancelDate} />
            </div>
            <button className="btn btn-default mt-3" onClick={toggleOpen}>
              <i className={`fa fa-${toggleIcon} mr-1`} />
              {toggleText}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        <motion.div
          layout
          className={`row`}
          style={{
            maxHeight: isOpen ? "none" : 0,
            overflow: "hidden",
          }}
        >
          <div className="col-12 col-md-12 col-lg-6 mb-2">
            <div className="p-3 bg-verylight border rounded mb-3">
              <ChestList />

              <BillingAddress isCanceled={isCanceled} />
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6 mb-2">
            {!isCanceled && (
              <div className="p-3 bg-verylight border rounded mb-3">
                <Elements stripe={stripePromise}>
                  <PaymentMethods />
                </Elements>
              </div>
            )}
            <div className="p-3 bg-verylight border rounded">
              <Invoices />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default SubscriptionItem;
