import React, { useState } from "react";
import Control from "../../../../../common/Controls/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import useBillingAddresses from "./useBillingAddresses";

const ModifyBillingAddress = ({ onFinish, selectedAddress }) => {
  const { subState, isSaving, errors, modifyBillingAddress } =
    useBillingAddresses(onFinish);

  const [newAddress, setNewAddress] = useState(selectedAddress);

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  return (
    <div>
      <div>
        <strong>Modifier l'adresse</strong>
      </div>

      <Control
        label="Nom"
        type="text"
        name="name"
        value={newAddress.name}
        placeholder="Maison, bureau, ..."
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Adresse"
        type="text"
        name="address1"
        value={newAddress.address1}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Adresse complément"
        type="text"
        name="address2"
        value={newAddress.address2}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Code postal"
        type="text"
        name="postalCode"
        value={newAddress.postalCode}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Ville"
        type="text"
        name="city"
        value={newAddress.city}
        change={handleChangeAddress}
        error={errors}
      />

      <div className="d-flex justify-content-between">
        <button className="btn btn-link" onClick={onFinish}>
          Annuler
        </button>
        <SaveBtn
          className="mt-2"
          save={() => modifyBillingAddress(newAddress)}
          isSaving={isSaving}
        />
      </div>
    </div>
  );
};

export default ModifyBillingAddress;
