import React from "react";
import useSubscriptions from "../useSubscriptions";
import { SubscriptionStoreProvider } from "../subscriptionContext/SubscriptionStore";
import subscriptionReducer from "../subscriptionContext/subscriptionReducer";
import SubscriptionItem from "./SubscriptionItem/SubscriptionItem";
import Loader from "../../../common/Loader";

const SubscriptionList = ({ openedSub = null }) => {
  const { filteredSubscriptions, isInit, actions } = useSubscriptions();

  return (
    <div>
      {filteredSubscriptions
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .map((sub, subk) => (
          <SubscriptionStoreProvider
            initialState={{
              ...sub,
            }}
            reducer={subscriptionReducer}
            key={"chest" + subk}
          >
            <SubscriptionItem
              gState={sub}
              k={subk}
              isSubOpen={openedSub == sub.id}
            />
          </SubscriptionStoreProvider>
        ))}
    </div>
  );
};

export default SubscriptionList;
