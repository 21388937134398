import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import Control from "../../common/Controls/Control";
import PasswordControl from "../../common/Controls/Inputs/PasswordControl";
const ActivateAccount = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  const activate = () => {
    if (props.match.params.token) {
      setIsLoading(true);
      Axios.post(API_URL + "user-customers/activate-account", {
        token: props.match.params.token,
        email: props.match.params.email,
        password: password,
      })
        .then((res) => {
          setIsActivated(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.data) setErrors(err.response.data);
        });
    }
  };

  if (isLoading) {
    return (
      <div
        style={{ borderRadius: 5 }}
        className="col-12 text-center shadow p-4 col-lg-6 col-md-8 m-auto bg-white"
      >
        <div>
          <p>Activation de votre compte en cours...</p>
          <Loader />
        </div>
      </div>
    );
  }

  if (isActivated) {
    return (
      <div
        style={{ borderRadius: 5 }}
        className="col-12 text-center shadow p-4 col-lg-6 col-md-8 m-auto bg-white"
      >
        <div className="text-secondary">
          <i className="fa fa-check fa-3x" />
          <h3 className="py-3">Votre compte a bien été activé.</h3>
          <p>Vous pouvez à présent vous connecter.</p>
          <Link
            to="/"
            className="btn btn-primary d-flex align-items-center justify-content-center"
          >
            Se connecter
          </Link>
        </div>
      </div>
    );
  }

  if (!props.match.params.token || !props.match.params.email) {
    <div
      style={{ borderRadius: 5 }}
      className="col-12 text-center shadow p-4 col-lg-6 col-md-8 m-auto bg-white"
    >
      <i className="fa fa-times fa-3x text-danger" />
      <p className="text-danger mb-0 mt-3">
        Un problème est survenu lors de l'activation de votre compte. Veuillez
        cliquer sur le lien d'activation qui vous a été envoyé par email.
      </p>
      <p className="mb-0 text-danger">{errors.other}</p>
      <Link
        to="/"
        className="btn btn-primary mt-3 d-flex align-items-center justify-content-center w-75 mx-auto"
      >
        Revenir à l'accueil
      </Link>
    </div>;
  }

  return (
    <Page>
      <div className="" style={{ maxWidth: 500, margin: "0 auto" }}>
        <div className="card shadow-sm">
          <div className="text-center p-4 p-lg-5 p-md-4">
            <div className="mb-0 mb-md-4 mt-4 mt-mb-0">
              <img src="/images/logo.svg" />
            </div>
            <h3 className="card-title mb-0">Activer mon compte</h3>
          </div>
          <div className="card-body p-4 p-lg-5 p-md-4">
            <p className="mb-0 text-center">
              Pour activer votre compte, merci de choisir un mot de passe.
            </p>
            <Control
              className="mt-5 mb-3"
              label="Choisissez un nouveau mot de passe"
              type="password"
              name="password"
              value={password}
              change={(e) => setPassword(e.target.value)}
              error={errors}
              isValid={!errors.password && password != "" && canSave}
            />
            <PasswordControl
              style={{ display: "none", visibility: "hidden", height: 0 }}
              password={password}
              minLength={6}
              minUppercase={1}
              minLowercase={3}
              minNumber={1}
              setCanSave={setCanSave}
            />
            <Control
              label="Confirmez votre mot de passe"
              type="password"
              name="passwordConfirm"
              value={passwordConfirm}
              change={(e) => setPasswordConfirm(e.target.value)}
              error={errors}
              isValid={!errors.passwordConfirm && passwordConfirm != ""}
            />
            <div className="text-danger mt-2">{errors.other}</div>
            <button
              disabled={!canSave || !passwordsEqual}
              className={"btn mt-4 w-100 btn-primary"}
              onClick={activate}
            >
              Activer mon compte
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ActivateAccount;
