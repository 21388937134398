import React, { useState, useEffect } from "react";
import Avatar from "../../common/Avatar";
import useStore from "../../../context/useStore";
import ImageFileCrop from "../../common/ImageFileCrop/ImageFileCrop";
import Control from "../../common/Controls/Control";
import PasswordControl from "../../common/PasswordControl";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import tools from "../../../helpers/tools";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";

const UserInfo = () => {
  const [state, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [canSave, setCanSave] = useState(true);
  const [passwordsEqual, setPasswordsEqual] = useState(true);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [modifyAvatar, setModifyAvatar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "user-customers/me")
      .then((res) => {
        setUser({
          ...res.data,
          firstname:
            res.data.firstname === "À renseigner" ? "" : res.data.firstname,
          lastname:
            res.data.lastname === "À renseigner" ? "" : res.data.lastname,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPasswordsEqual(user.password === user.passwordConfirm);
  }, [user.password, user.passwordConfirm]);

  const updateInput = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const save = () => {
    setIsLoading(true);
    Axios.patch(API_URL + "user-customers/me", user)
      .then((res) => {
        setUser(res.data);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, mustSetupAccount: false },
        });
        setErrors({});
        toast.success("Vos informations ont bien été mises à jour");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const savePassword = () => {
    setIsLoading(true);
    Axios.patch(API_URL + "user-customers/me/password", {
      password: user.password,
    })
      .then((res) => {
        setErrors({});
        toast.success("Votre mot de passe a bien été modifié");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error("Une erreur est survenue");
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  return modifyAvatar ? (
    <ImageFileCrop setModifyAvatar={setModifyAvatar} />
  ) : (
    <>
      {/* <div className="col-12 position-relative d-flex flex-column flex-md-row justify-content-start align-items-center">
        
        <h2 className=" mt-3" style={{ textTransform: "capitalize" }}>
          {user.firstname} {user.lastname}
        </h2>
      </div> */}
      <div className="col-12 mt-4">
        <Control
          label="Nom"
          type="text"
          name="lastname"
          value={user.lastname}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />
        <Control
          label="Prénom"
          type="text"
          name="firstname"
          value={user.firstname}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />

        <Control
          label="Email"
          type="email"
          name="email"
          value={user.email}
          change={updateInput}
          error={errors}
          setError={setErrors}
          required={true}
        />

        <div className="d-flex align-items-center justify-content-between  flex-wrap">
          <button
            className={`btn btn-secondary animated fast ${
              showPassword ? "fadeOut" : "fadeIn"
            }`}
            onClick={() => setShowPassword((s) => !s)}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {isLoading ? <Loader color="white" /> : "Changer le mot de passe"}
          </button>
          <button
            className="btn btn-success"
            onClick={save}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {isLoading ? <Loader color="white" /> : "Mettre à jour"}
          </button>
        </div>
      </div>

      {showPassword && (
        <div className={`col-12 mt-4 animated faster fadeInUp `}>
          <Control
            label="Nouveau mot de passe"
            type="password"
            name="password"
            value={user.password}
            change={updateInput}
            error={errors}
            setError={setErrors}
            autocomplete="new-password"
            required={true}
          />

          <Control
            label="Retapez le mot de passe"
            type="password"
            name="passwordConfirm"
            value={user.passwordConfirm}
            change={updateInput}
            error={errors}
            setError={setErrors}
            isValid={
              user.passwordConfirm == user.password &&
              user.password &&
              user.password != ""
            }
            required={true}
          />
          <div className="text-right ">
            <button
              className="btn btn-success"
              disabled={
                user.passwordConfirm != user.password ||
                !user.password ||
                user.password == ""
              }
              onClick={savePassword}
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {isLoading ? (
                <Loader color="white" />
              ) : (
                "Mettre à jour mon mot de passe"
              )}
            </button>
          </div>
        </div>
      )}
      <div className="col-12 mt-2">
        {errors.other && (
          <div className="alert alert-danger text-center">{errors.other}</div>
        )}
      </div>
    </>
  );
};

export default UserInfo;
